import { GatsbyImage } from "../types"
import { IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import { filterUndefined } from "./arrays"

export const generateSources = (
  images: [GatsbyImage?, number?][]
): IGatsbyImageData => {
  if (images.length === 1) {
    if (images[0][0]) {
      return images[0][0].gatsbyImageData
    } else {
      throw new Error("Property gatsbyImageData is required")
    }
  } else if (!images.filter((image) => image[0]).length) {
    throw new Error("At least one item is required")
  } else if (!images.filter((image) => image[1]).length) {
    throw new Error("Images conflict - breakpoint conditions are missing")
  } else {
    const artDirectionImages = filterUndefined(
      images.map(([image, min], index) => {
        const prev = images[index - 1]
        const next = images[index + 1]
        const nextMin = next && next[1]

        const defaultMedia = !prev && !min && false
        const tabletMedia =
          min &&
          nextMin &&
          `(min-width: ${nextMin + 1}px) and (max-width: ${min}px)`
        const mobileMedia = !next && min && `(max-width: ${min}px)`

        const media = defaultMedia || tabletMedia || mobileMedia || ""
        return (
          image && {
            image: image.gatsbyImageData,
            media: media && media,
          }
        )
      })
    )

    return withArtDirection(artDirectionImages[0].image, artDirectionImages)
  }
}
